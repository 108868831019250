<template>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="py-5" elevation="8">
            <h1
              class="text-center primary--text text-h4"
              style="font-family: Kelly Slab !important"
            >
              Planos para o CPF/CNPJ {{ $store.state.cpf }}
            </h1>
            <h4 class="text-center">
              Data:
              {{
                new Date()
                  .getDate()
                  .toString()
                  .padStart(2, "0")
              }}/ {{ (new Date().getMonth() + 1).toString().padStart(2, "0") }}/
              {{ new Date().getFullYear() }}
            </h4>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" v-if="mostra1x">
          <v-card class="mx-auto" max-width="344">
            <v-list-item three-line>
              <v-list-item-content>
                <div
                  class="overline mb-4"
                  style="font-family: Kelly Slab !important"
                >
                  {{ credor }}
                </div>
                <v-list-item-title class="headline mb-1"
                  >A Vista</v-list-item-title
                >
                <v-list-item-subtitle>
                  <div class="text--primary">
                    Valor R$ {{ plano1x.valor | dinheiro }}
                  </div>
                  <div class="text--primary">*****</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" @click="negociar('1x')">
                Negociar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col xs="12" v-if="mostra2x">
          <v-card class="mx-auto" max-width="344">
            <v-list-item three-line>
              <v-list-item-content>
                <div
                  class="overline mb-4"
                  style="font-family: Kelly Slab !important"
                >
                  {{ credor }}
                </div>
                <v-list-item-title class="headline mb-1"
                  >Entrada + 1x</v-list-item-title
                >
                <v-list-item-subtitle>
                  <div class="text--primary">
                    Entrada de R$ {{ plano2x.valor | dinheiro }}
                  </div>
                  <div class="text--primary">
                    Mais 1 X de R$
                    {{ plano2x.valorP | dinheiro }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" @click="negociar('2x')">
                Negociar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col xs="12" v-if="mostra3x">
          <v-card class="mx-auto" max-width="344">
            <v-list-item three-line>
              <v-list-item-content>
                <div
                  class="overline mb-4"
                  style="font-family: Kelly Slab !important"
                >
                  {{ credor }}
                </div>
                <v-list-item-title class="headline mb-1"
                  >Entrada + 2x</v-list-item-title
                >
                <v-list-item-subtitle>
                  <div class="text--primary">
                    Entrada de R$ {{ plano3x.valor | dinheiro }}
                  </div>
                  <div class="text--primary">
                    Mais 2 X de R$
                    {{ plano3x.valorP | dinheiro }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" @click="negociar('3x')">
                Negociar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col xs="12" v-if="mostra5x">
          <v-card class="mx-auto" max-width="344">
            <v-list-item three-line>
              <v-list-item-content>
                <div
                  class="overline mb-4"
                  style="font-family: Kelly Slab !important"
                >
                  {{ credor }}
                </div>
                <v-list-item-title class="headline mb-1"
                  >Entrada + 4x</v-list-item-title
                >
                <v-list-item-subtitle>
                  <div class="text--primary">
                    Entrada de R$ {{ plano5x.valor | dinheiro }}
                  </div>
                  <div class="text--primary">
                    Mais 4 X de R$
                    {{ plano5x.valorP | dinheiro }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions>
              <v-btn color="primary" @click="negociar('5x')">
                Negociar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  //import { api } from "@/conf/api";
  //import {calcDescViasat} from "@/conf/utilitario";
  
  export default {
    name: "PlanoUnismg",
    data() {
      return {
        credor: "",
        divida: {},
        mostra1x: true,
        mostra2x: false,
        mostra3x: false,
        mostra5x: false,
        plano1x: {
          valor: 0,
          parcelas: 0,
          codbarras: "",
          valorP: 0,
        },
        plano2x: {
          valor: 0,
          parcelas: 1,
          codbarras: "",
          valorP: 0,
        },
        plano3x: {
          valor: 0,
          parcelas: 2,
          codbarras: "",
          valorP: 0,
        },
        plano5x: {
          valor: 0,
          parcelas: 4,
          codbarras: "",
          valorP: 0,
        },
      };
    },
    filters: {
      dinheiro: function(value) {
        return parseFloat(value)
          .toFixed(2)
          .replace(".", ",");
      },
      dataF: function(d2) {
        let d = d2.substr(0, 10);
        return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
      },
    },
    async beforeMount() {
      this.credor = this.$store.state.credor_atual;
      this.divida = this.$store.state.divida_atual;
      if (this.divida.cpcgc == "31404878000116" ) {
        this.pegarPlanosUnismg();
      }
    },
    methods: {
      async negociar(plano) {
        try {
          await this.$store.dispatch("marcaPacoteAtual", "Unismg");
  
          if (plano == "1x") {
            //
            await this.$store.dispatch("marcaNegocAtual", this.plano1x);
            await this.$store.dispatch("quantasParcelasFaltam", 0);
          }
          if (plano == "2x") {
            //
            await this.$store.dispatch("marcaNegocAtual", this.plano2x);
            await this.$store.dispatch("quantasParcelasFaltam", 1);
          }
          if (plano == "3x") {
            //
            await this.$store.dispatch("marcaNegocAtual", this.plano3x);
            await this.$store.dispatch("quantasParcelasFaltam", 2);
          }
          if (plano == "5x") {
            //
            await this.$store.dispatch("marcaNegocAtual", this.plano5x);
            await this.$store.dispatch("quantasParcelasFaltam", 4);
          }
  
          // this.$router.push(this.$store.state.divida_atual.cpcgc);
          this.$router.push("PlanoUnismg2");
        } catch (e) {
          console.log(e);
        }
      },
      async pegarPlanosUnismg() {
        
       //await this.$store.dispatch("marcaPacoteAtual", "N-UNISMG");
          // await this.$store.dispatch("marcaNegocAtual", item);
       //   await this.$store.dispatch("marcaVencAtual", this.picker);
  
       //   await this.$store.dispatch("marcaEmail", this.email);
  
        
        let valores = this.$store.state.unismg_valores
        let parc_minima = valores.parc_min

       console.log('v',valores)

        try {
          //  plano1 - calcDescViasat => parametros saldo, desconto < 365 dias, desc> 365, atraso
          
          // retorna {valor:arredonda(valor),saldo,desc,atraso}
         // let valor = saldo - (saldo * desc)
         // console.log('n',atraso,desc,valor)
          this.plano1x.valor = valores._1x        //arredonda(valor);
          this.mostra1x = true;
         
          // 3x
         // valorP = valorP / 2;
          this.plano3x.valor = valores.entrada
          this.plano3x.valorP = valores._3xdemais
          if(valores._3xdemais> parc_minima && valores.codacordo != '116'){
            this.mostra3x = true;
          }

          //5x
          this.plano5x.valor = valores.entrada
          this.plano5x.valorP = valores._5xdemais
          if(valores._5xdemais> parc_minima && valores.codacordo != '116'){
            this.mostra5x = true;
          }
            
          
          
         
        } catch (e) {
          this.$router.push("erroPage");
        }
      },
    },
  };
  </script>
  
  <style></style>
  